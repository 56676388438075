html {
	font-weight: 400;
	line-height: 1.25;
	font-family: var(--font-family-body);
}

body {
	font-size: var(--font-size-paragraph);
	color: var(--color-midnight-blue);
}

h1,
h2,
h3,
h4 {
	font-family: var(--font-family-heading);
	font-weight: 400;
	font-style: normal;
	line-height: 1.1;
}

h1,
.h1 {
	font-size: 2rem;
}

h2,
.h2 {
	font-size: 1.4rem;
}

h3,
.h3 {
	font-size: 1.4rem;
}

h4,
.h4 {
	font-size: 1.2rem;
}

/**
 * Vertical flow of typography
 */

p,
ul,
ol,
table,
figure,
blockquote,
dl,
h1,
h2,
h3,
h4 {
	margin-top: 0;
	margin-bottom: 0;
}

p:not(:last-child),
ul:not(:last-child),
ol:not(:last-child),
table:not(:last-child),
figure:not(:last-child),
blockquote:not(:last-child),
dl:not(:last-child) {
	margin-bottom: var(--paragraph-margin);
}

h1:not(:last-child),
.h1:not(:last-child) {
	margin-bottom: var(--paragraph-margin);
}

h2:not(:first-child),
.h2:not(:first-child) {
	margin-top: calc(var(--paragraph-margin) * 2);
}

h2:not(:last-child),
.h2:not(:last-child) {
	margin-bottom: calc(var(--paragraph-margin) * 0.5);
}

h3:not(:first-child),
.h3:not(:first-child) {
	margin-top: calc(var(--paragraph-margin) * 2);
}

h3:not(:last-child),
.h3:not(:last-child) {
	margin-bottom: var(--paragraph-margin);
}

h4:not(:first-child),
.h4:not(:first-child) {
	margin-top: calc(var(--paragraph-margin) * 1.5);
}

h4:not(:last-child),
.h4:not(:last-child) {
	margin-bottom: calc(var(--paragraph-margin) * 0.5);
}
