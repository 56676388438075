.button {
	--button-shadow: #e2ba41;
	--button-offset-x: 0.45rem;
	--button-offset-y: 0.7rem;
	background-color: var(--color-white);
	min-width: 14rem;
	max-width: 100%;
	border-radius: 3rem;
	font-family: var(--font-family-heading);
	font-size: 2rem;
	letter-spacing: 0.02em;
	color: var(--color-midnight-blue);
	padding: 1.75rem;
	display: inline-block;
	text-align: center;
	line-height: 1.2;
	text-decoration: none;
	overflow-wrap: break-word;
	cursor: pointer;
	box-shadow: var(--button-shadow) var(--button-offset-x) var(--button-offset-y)
		0;
	transition-property: transform, box-shadow;
	transition-duration: 70ms;
	transition-timing-function: cubic-bezier(0.67, 0.16, 0.84, 0.39);
	user-select: none;
	-webkit-touch-callout: none;
}

.button:disabled {
	opacity: 0.5;
	cursor: default;
	box-shadow: #e2ba41 0 0 0;
	transform: translate(var(--button-offset-x), var(--button-offset-y));
}

.button:active {
	box-shadow: #e2ba41 0 0 0;
	transform: translate(var(--button-offset-x), var(--button-offset-y));
}
