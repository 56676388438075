* {
	box-sizing: border-box;
}

*::before,
*::after {
	box-sizing: inherit;
}

html {
	font-size: 20px; /* <- 1rem */
	font-family: var(--font-family);

	color: var(--color-text);
	background-color: var(--color-background);
	-webkit-tap-highlight-color: transparent;
}

body {
	max-width: 100vw;
	overflow-x: hidden;
	overflow-y: auto;
}

body:not(:has(#__next > .no-margin-bottom:last-child)) {
	margin-bottom: max(var(--page-margin), env(safe-area-inset-bottom));
}

#__next > .padding-bottom-when-last:last-child {
	padding-bottom: max(var(--page-margin), env(safe-area-inset-bottom));
}

button {
	appearance: none;
	color: inherit;
	display: inline-block;
	border: 0;
	padding: 0;
	margin: 0;
}

a {
	color: inherit;
}

textarea {
	resize: vertical;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	font-size: 0.9rem;
}

table th {
	font-weight: bold;
	text-align: left;
}

table td {
}

hr {
	border: 0;
	border-top: 2px solid var(--color-warm-mustard);
	margin-top: 2rem;
	margin-bottom: 2rem;
}
