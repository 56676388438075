.banner {
	background-color: var(--color-midnight-blue);
	color: var(--color-light-yellow);
	padding: 2rem var(--page-margin--small);

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
}

.banner p {
	text-align: center;
}

.banner p:not(:last-child) {
	margin-bottom: 0;
}

.button {
	font-size: 1.25rem;
	min-width: 8rem;
	padding: 0.65rem 1.2rem;
	--button-shadow: rgba(0, 0, 0, 0.2);
	--button-offset-x: 0.2rem;
	--button-offset-y: 0.4rem;
}

@media (max-width: 768px) {
	.banner {
		flex-direction: column;
		align-items: center;
	}
}
