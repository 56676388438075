:root {
	--font-size-paragraph: 1.1rem;

	--font-family-body: Helvetica, Arial, sans-serif;
	--font-family-heading: 'cooper-black-std', serif;

	--page-margin--small: 0.8rem;
	--page-margin: min(2rem, 8vw);
	--paragraph-margin: 1rem;
	--page-width: 40rem;
	--walk-header-height: 3.4rem;
	--runner-width: 10rem;

	/* Palette */
	--color-white: #ffffff;
	--color-black: #000;
	--color-dark-grey: #4a4433;
	--color-midnight-blue: #1b4390;
	--color-dark-yellow: #f7cb44;
	--color-light-yellow: #fcd96f;
	--color-mustard: #e2ba41;
	--color-warm-mustard: #dda6007a;

	--color-light-red: #e9623e;
	--color-dark-red: #af3130;
	--color-light-green: #f0eac1;
	--color-dark-green: #23a443;
	--color-almost-black: #333930;
	--color-eggshell: #efeac2;

	/* Assignment */
	--color-background: var(--color-dark-yellow);
	--color-dim-background: rgb(221, 166, 0, 0.31);
	--color-heading: var(--color-midnight-blue);
	--color-text: var(--color-dark-grey);
	--color-focus: var(--color-midnight-blue);
	--color-highlighted-background: #061832;
	--color-highlighted-heading: #aedea4;
}

@media (max-width: 400px) {
	:root {
		--page-margin: min(1.2rem, 8vw);
	}
}
